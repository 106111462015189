import { Component, Input, OnInit } from '@angular/core';
import { CustomerInterface } from '../../../interfaces/customer-interface';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { toggleLoader } from '../../../../../shared/helpers/functions-helper.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ModalServiceAlert } from '../../../../../shared/components/modal-alert/service/modal-alert.service';
import { GeneralParameterInterface, generalParameterResponseInterface } from '../../../../../shared/interfaces/general-parameter';
import { ParametersService } from '../../../../../shared/services/parameters.service';
import { CustomersService } from '../../../services/customers.service';

@Component({
      selector: 'app-customers-edit',
      standalone: true,
      imports: [
            CommonModule,
            ReactiveFormsModule,
            FormsModule,
            CurrencyPipe
      ],
      templateUrl: './customers-edit.component.html',
      styleUrl: './customers-edit.component.scss'
})
export class CustomersEditComponent {
      @Input() customer!: CustomerInterface;
      customerForm!: FormGroup;
      cities: GeneralParameterInterface[] = [];
      canSendWhatsapp: boolean = false;

      constructor(
            private formBuilder: FormBuilder,
            public activeModal: NgbActiveModal,
            private loaderService: LoaderService,
            private modalService: ModalServiceAlert,
            private parameterService: ParametersService,
            private customerService: CustomersService
      ) { }

      /**
       * * Inicializa el formulario y obtiene la lista de ciudades.
       */
      ngOnInit() {
            this.initForm();
            this.getCities();
      }

      /**
       * * Inicializa el formulario de la vista.
       */
      initForm() {
            this.customerForm = this.formBuilder.group({
                  identification: [this.customer.identification],
                  nit: [this.customer.nit],
                  name: [this.customer.name],
                  last_name: [this.customer.last_name],
                  buss_name: [this.customer.buss_name],
                  email: [this.customer.email],
                  phone: [this.customer.phone],
                  city_id: [this.customer.city_id],
                  address: [this.customer.address],
                  businness_address: [this.customer.businness_address],
                  total_approved_quota: [this.customer.total_approved_quota],
            });
      }

      /**
       * * Cierra el modal.
       */
      closeModal(): void {
            this.activeModal.dismiss('Cross click');
      }

      /**
       * * Consume servicio para obtener las ciudades.
       */
      getCities() {
            this.parameterService.getCities().subscribe((response: generalParameterResponseInterface) => {
                  if (response.ok) {
                        this.cities = response.data;
                  } else {
                        this.modalService.openModal("Error", response.message, "error");
                  }
            }, (error: any) => {
                  this.modalService.openModal("Error", "Ocurrió un error al obtener el listado de ciudades", "error");
            });
      }

      /**
       * * El metodo permite enviar plantilla whatsapp para firmar pagare Deceval.
       */
      sendWhatsappLinkDeceval() {
            toggleLoader(this.loaderService, true, 'Enviando plantilla de whatsapp...');
            this.customerService.sendWhatsappLinkDeceval(this.customer.id).subscribe((res: any) => {
                  toggleLoader(this.loaderService, false);
                  if (res.ok) {
                        this.modalService.openModal("Exito", res.message, "success");
                  } else {
                        this.modalService.openModal("Error", "Ocurrió un error al enviar el link de whatsapp", "error");
                  }
            })
      }

      /**
       * * Actualiza la información del cliente.
       */
      customerUpdate(): void {
            toggleLoader(this.loaderService, true);
            const messageError = "Error al actualizar el cliente";
            this.customerService.updateCustomer(this.customerForm.value, this.customer.id).subscribe((response: any) => {
                  toggleLoader(this.loaderService, false);
                  if (response.ok) {
                        if (response.message && response.message.includes("Se enviará un nuevo enlace")) {
                              this.canSendWhatsapp = true;
                        }
                        this.modalService.openModal("Exito", response.message, "success");
                  } else {
                        this.modalService.openModal("Error", response.message, "error");
                  }
            }, (err: any) => {
                  toggleLoader(this.loaderService, false);
                  this.modalService.openModal("Error", messageError, "error");
            });
      }



}
